import { PackageType } from 'utils/enum-util';
import { subDays, subMonths } from 'date-fns';

export const qualification = [
  'PhD',
  'Master Degree',
  'Bachelor Degree',
  'Associate Degree',
  'Baccalaureate Degree',
  'Other',
  // 'Certificate',
  // 'Associate Degree',
  // 'Diploma',
  // 'Bachelor Degree',
  // 'Master Degree',
  // 'Doctorate Degree',
];

export const jobLevels = [
  'Non Prior Experience',
  'Junior Level',
  'Supervisor / Manager',
  'Top Executive Level',
];

export const convertToMin = (value: number) => {
  return Math.floor(value / 60000);
};

export const ListDays = [
  { id: 1, label: 'Monday' },
  { id: 2, label: 'Tuesday' },
  { id: 3, label: 'Wednesday' },
  { id: 4, label: 'Thurday' },
  { id: 5, label: 'Friday' },
  { id: 6, label: 'Saturday' },
  { id: 7, label: 'Sunday' },
];

export const defaultMapProps = {
  center: {
    lat: 11.5495323,
    lng: 104.9413468,
  },
  zoom: 15,
};

export const validatePatterns = {
  phoneNumber: /0[0-9]{8}$|0[0-9]{9}$/,
  numberOnly: /^-?[0-9]\d*(\.\d+)?$/,
  email: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
};

export const UserType = {
  employee: 1,
  employer: 2,
};

export const OfferLetterStatus = {
  DRAFT: 1,
  ACCEPT: 2,
  REJECT: 3,
};

export const ApplyJobStatus = {
  UNDEFINED: 0,
  PENDING: 1,
  FAILED: 2,
  FAILED_SHORTLISTED: 3,
  INTRODUCED: 4,
  PASSED: 5,
  IN_REVIEW: 6,
  INTERVIEW: 7,
  FINAL_INTERVIEW: 8,
  SHORTLISTED: 9,
  REJECTED: 10,
  CANNOT_REACH: 11,
  TERMINATED: -1,
};

export const JobStatusOption = Object.entries(ApplyJobStatus)
  .filter((status) => ![ApplyJobStatus.UNDEFINED].includes(status[1]))
  .map((s) => ({
    title: s[0].replace('_', ' ').toLocaleLowerCase(),
    value: s[1],
  }));

export const InterviewReasonType = {
  reSchedule: 1,
  changedMyMind: 2,
  notInterested: 3,
  other: 4,
};

export const InterviewMethod: { [key: number]: string } = {
  1: 'Offline Interview',
  2: 'Online Interview',
};

export const InterviewStatusFilter = [
  { value: 1, label: 'Open' },
  { value: 2, label: 'Completed' },
  { value: 3, label: 'Cancel' },
];

export const InterviewStatus: {
  [key: number]: string;
} = {
  1: 'pending',
  2: 'completed',
  3: 'closed',
  4: 'cancelled',
  5: 'accepted',
  6: 'rejected',
  7: 'started',
};

export const InterviewStatusObj: {
  [key in
    | 'pending'
    | 'completed'
    | 'closed'
    | 'cancelled'
    | 'accepted'
    | 'rejected'
    | 'started']: {
    status: number;
    label: string;
  };
} = {
  pending: { status: 1, label: 'Pending' },
  completed: { status: 2, label: 'Completed' },
  closed: { status: 3, label: 'Closed' },
  cancelled: { status: 4, label: 'Cancelled' },
  accepted: { status: 5, label: 'Accepted' },
  rejected: { status: 6, label: 'Rejected' },
  started: { status: 7, label: 'Online Interview Started' },
};

export const PLANS = ['free', 'basic', 'signature', 'signature+'];
export const PackagePlansObj = {
  free: { title: 'Freemium', value: PackageType.free },
  basic: { title: 'Basic', value: PackageType.basic },
  signature: { title: 'Signature', value: PackageType.signature },
  signaturePlus: { title: 'Signature Plus', value: PackageType.signaturePlus },
};
export const PackagePlansArray = Object.values(PackagePlansObj);

export const ALL_TEST: {
  [key in 'motivation-skill-test' | 'career-values-test' | 'disc']: {
    title: string;
    slug: string;
    reqSlug: string;
  };
} = {
  'motivation-skill-test': {
    title: 'Motivation Skill Test',
    slug: 'motivation-skill-test',
    reqSlug: 'get-question-motivated-skill',
  },
  'career-values-test': {
    title: 'Career Values Test',
    slug: 'career-values-test',
    reqSlug: 'get-question-career',
  },
  disc: {
    title: 'DISC',
    slug: 'disc',
    reqSlug: 'get-question-disc-assessment',
  },
};

export const Links = {
  facebook: 'https://www.facebook.com/Workingna',
  linkedIn: 'https://www.linkedin.com/company/workingna/',
  instagram: 'https://www.instagram.com/workingna/?hl=en',
  telegram: 'https://t.me/workingna1',

  ios: 'https://apps.apple.com/kh/app/workingna/id1449408274',
  android:
    'https://play.google.com/store/apps/details?id=com.mycareersfinder.workingna&hl=en&gl=US',
};

export const langs = [
  {
    title: 'English',
    shortcut: 'EN',
    value: 'en',
    img: `${process.env.NEXT_PUBLIC_BASE_PATH}/images/languages/united-states-of-america-flag-round-icon-256.png`,
  },
  {
    title: 'ខ្មែរ',
    shortcut: 'KH',
    value: 'kh',
    img: `${process.env.NEXT_PUBLIC_BASE_PATH}/images/languages/cambodia-flag-round-icon-256.png`,
  },
  {
    title: '中文',
    shortcut: 'ZH',
    value: 'zh',
    img: `${process.env.NEXT_PUBLIC_BASE_PATH}/images/languages/china-flag-round-icon-256.png`,
  },
];

export const mapStyle = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

// auto generate background
export const changeBackground = (name?: string) => {
  var hash = 0;
  let i;
  if (name) {
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const cities = [
  'Banteay Meanchey',
  'Battambang',
  'Kampong Cham',
  'Kampong Chhnang',
  'Kampong Speu',
  'Kampong Thom',
  'Kampot',
  'Kandal',
  'Kep',
  'Koh Kong',
  'Kratie',
  'Mondulkiri',
  'Oddar Meanchey',
  'Pailen',
  'Phnom Penh',
  'Preah Vihear',
  'Prey Veng',
  'Pursat',
  'Ratanakiri',
  'Siem Reap',
  'Sihanoukville',
  'Stung Treng',
  'Svay Rieng',
  'Takeo Province',
  'Tbong Khmum',
];

export const employeeType = ['Full-Time', 'Part-Time'];

export const userType = [
  { name: 'Admin', type: 1 },
  { name: 'Vice Admin', type: 2 },
  { name: 'Sale Editor', type: 3 },
  { name: 'Recruitment Editor', type: 4 },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DateRangeOptions = [
  {
    label: 'Last 7 days',
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  },
  {
    label: 'Last 30 days',
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  },
  {
    label: 'Last 3 months',
    startDate: subMonths(new Date(), 3),
    endDate: new Date(),
  },
  {
    label: 'Last 6 months',
    startDate: subMonths(new Date(), 6),
    endDate: new Date(),
  },
  {
    label: 'Last 12 months',
    startDate: subMonths(new Date(), 12),
    endDate: new Date(),
  },
];

export const NoPermission: {
  [key: number]: { role: string; not_permission: string[] };
} = {
  1: { role: 'Admin', not_permission: [] },
  2: { role: 'Vice_Admin', not_permission: ['COMMON', 'EMPLOYER'] },
  3: {
    role: 'Sale_Editor',
    not_permission: ['JOB_SEEKERS', 'INTERVIEWS', 'USER_LIST'],
  },
  4: {
    role: 'Recruitment',
    not_permission: ['COMMON', 'EMPLOYER', 'USER_LIST'],
  },
};
