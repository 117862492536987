import { TbUsers, TbUser, TbVideoMinus, TbReportSearch } from 'react-icons/tb';
import { CgViewGrid } from 'react-icons/cg';
import { HiOutlineChartPie } from 'react-icons/hi';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { RiNodeTree, RiFileList2Line, RiSettings3Line, RiSurveyLine } from 'react-icons/ri';
import { MdCampaign } from 'react-icons/md'
import { ROUTE_PATH } from './route-util';

import CampaignIcon from '@mui/icons-material/Campaign';

type Navigation = {
  menuStartIcon?: React.ReactNode;
  menuEndIcon?: React.ReactNode;
  menuTile: string;
  menuLinks?: string;
  subMenu: {
    subTile?: string;
    subLinks?: string;
  }[];
}[];

const navigations: Navigation = [
  {
    menuTile: 'Dashboard',
    menuStartIcon: <HiOutlineChartPie size='20' />,
    menuLinks: ROUTE_PATH.dashboard,
    subMenu: [],
  },
  {
    menuTile: 'Job',
    menuStartIcon: <CgViewGrid size='20' />,
    menuLinks: ROUTE_PATH.job,
    subMenu: [],
  },
  {
    menuTile: 'Announcement',
    menuStartIcon: <MdCampaign size='20' />,
    menuLinks: ROUTE_PATH.announcement.root,
    subMenu: [],
  },
  {
    menuTile: 'Questionnaires',
    menuStartIcon: <RiSurveyLine size='20' />,
    menuLinks: ROUTE_PATH.questionaire.root,
    subMenu: [],
  },
  {
    menuTile: 'Common',
    menuStartIcon: <RiNodeTree size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.common.category,
        subTile: 'Category',
      },
      {
        subLinks: ROUTE_PATH.common.industry,
        subTile: 'Industry',
      },
    ],
  },
  {
    menuTile: 'Job Seeker',
    menuStartIcon: <TbUser size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.jobSeeker.jobSeeker,
        subTile: 'Job Seekers',
      },
      {
        subLinks: ROUTE_PATH.jobSeeker.jobApplied,
        subTile: 'Job Applied',
      },
      {
        subLinks: ROUTE_PATH.jobSeeker.interviews,
        subTile: 'Interviews',
      },
      {
        subLinks: ROUTE_PATH.jobSeeker.offerLetter,
        subTile: 'Offer Letter',
      },
    ],
  },
  {
    menuTile: 'Employer',
    menuStartIcon: <TbUsers size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.employer.list,
        subTile: 'Employers',
      },
      {
        subLinks: ROUTE_PATH.employer.package,
        subTile: 'Package',
      },
      {
        subLinks: ROUTE_PATH.employer.cvDownload,
        subTile: 'CV Download',
      },
    ],
  },

  {
    menuTile: 'Blogging',
    menuStartIcon: <RiFileList2Line size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.blogging.post,
        subTile: 'Post',
      },
      {
        subLinks: ROUTE_PATH.blogging.category,
        subTile: 'Category',
      },
    ],
  },
  {
    menuTile: 'The Learn More',
    menuStartIcon: <TbVideoMinus size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.learnMore.video,
        subTile: 'Video',
      },
      {
        subLinks: ROUTE_PATH.learnMore.category,
        subTile: 'Category',
      },
    ],
  },
  {
    menuTile: 'User Management',
    menuStartIcon: <AiOutlineUserSwitch size='20' />,
    subMenu: [
      {
        subLinks: ROUTE_PATH.user.profile,
        subTile: 'Profile',
      },
      {
        subLinks: ROUTE_PATH.user.userList,
        subTile: 'User List',
      },
    ],
  },
  {
    menuTile: 'Assessment Report',
    menuStartIcon: <TbReportSearch size='20' />,
    menuLinks: ROUTE_PATH.assessmentReport,
    subMenu: [],
  },
  {
    menuTile: 'Configuration',
    menuStartIcon: <RiSettings3Line size='20' />,
    menuLinks: ROUTE_PATH.configuation,
    subMenu: [],
  },
];

export default navigations;
