import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getPersistedState,
  getPersistedStateSession,
  persistState,
  persistStateSession,
} from 'utils/persist-util';

interface IAuthState {
  authed?: boolean;
  keepLogged?: boolean;
  token?: string;
  id?: number;
  adminProfile?: IAuth.AdminProfile;
  userType?: number;
  expired?: string;
}

interface IAuthContext {
  authState: IAuthState;
  setAuthState: React.Dispatch<React.SetStateAction<IAuthState>>;
}

const AuthContextInitValues = {
  authState: { authed: false },
  setAuthState: () => {},
};

export const AuthContext = createContext<IAuthContext>(AuthContextInitValues);

interface IAuthWrapper {
  children: React.ReactNode;
}

export function AuthWrapper({ children }: IAuthWrapper) {
  const initMount = useRef(true);

  const local =
    getPersistedState(process.env.REACT_APP_PERSIST_AUTH) ||
    AuthContextInitValues.authState;
  const session =
    getPersistedStateSession(process.env.REACT_APP_PERSIST_AUTH) ||
    AuthContextInitValues.authState;

  const [authState, setAuthState] = useState<IAuthState>(
    local?.keepLogged ? local : session
  );

  //Version
  useEffect(() => {
    const vers = getPersistedState('version');
    if (vers !== process.env.REACT_APP_PERSIST_VER) {
      localStorage.clear();
      sessionStorage.clear();
      setAuthState({ authed: false });
      persistState('version', process.env.REACT_APP_PERSIST_VER || '');
    }
  }, []);

  //SetValue to LocalStorage and SessionStorage
  useEffect(() => {
    if (!initMount.current) {
      (authState.keepLogged === false || authState.authed === false) &&
        persistStateSession(
          process.env.REACT_APP_PERSIST_AUTH || '',
          authState
        );
      (authState.keepLogged === true || authState.authed === false) &&
        persistState(process.env.REACT_APP_PERSIST_AUTH || '', authState);
    } else initMount.current = false;
  }, [authState]);

  return (
    <>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {children}
      </AuthContext.Provider>
    </>
  );
}

export function useAuthContext() {
  const { authState, setAuthState } = useContext(AuthContext);

  return {
    authState,
    setAuthState,
  };
}
