import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    highlight: Palette['primary'];
  }
  interface PaletteOptions {
    highlight: PaletteOptions['primary'];
  }

  interface TypeBackground {
    bgGradient?: string;
  }
}

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      // 'HelveticaNeueCyr',
      // 'Content',
      // 'OleoScriptSwashCaps',
      'Roboto',
      'Raleway',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    highlight: {
      main: '#1C99E6',
      light: '#4791FF',
      dark: '#8543E0',
    },
    primary: {
      main: '#DA453F',
      light: '#FFF8F8',
    },
    secondary: {
      main: '#06334C',
      light: '#0A405E',
      dark: '#393939',
    },
    info: {
      main: '#B5BECC',
      light: '#FCFCFC',
      dark: '#F1F3F6',
    },
    grey: {
      50: '#FAFAFB',
      100: '#F1F3F6',
      200: '#F8F8F8',
      300: '#7676801F',
      400: '#C6C6C8',
      500: '#3C3C4399',
      // 600: '#656565',
      900: '#222222',
    },
    text: {
      primary: '#000000',
      // secondary: '#9E9E9E',
      secondary: '#656565',
    },
    background: {
      default: '#F6F7F9',
      paper: '#FFFFFF',
      bgGradient:
        'linear-gradient(90deg, rgba(228,111,74,1) 20%, rgba(218,69,63,1) 100%)',
    },
    warning: {
      main: '#E46F4A',
      light: '#FACC14',
      dark: '#FAAD14',
    },
    success: {
      main: '#4EB223',
      light: '#2FC25B',
      dark: '#13C2C2',
    },
    divider: '#F1F1F1',
    error: {
      main: '#ECA19E',
      light: '#F8DCDB',
      dark: '#F5222D',
    },
  },
});

theme.shadows[1] = '0px 3px 15px #0000000F';
theme.shadows[2] = '0px 8px 15px #00000021';
theme.shadows[3] = '0px 2px 8px #00000026';
theme.shadows[4] = '0px 0px 16px #0000001A';

theme.components = {
  MuiAppBar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '50vh',
        textTransform: 'none',
        ':disabled': {
          background: theme.palette.action.disabledBackground,
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
};

export default theme;
