import { Popper, styled } from '@mui/material';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  // You can replace with `PopperUnstyled` for lower bundle size.
  zIndex: theme.zIndex.drawer + 1,
  minWidth: 230,
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    right: 4,
    marginTop: '-1.2em',
    width: '3em',
    height: '1.2em',
    '&::before': {
      borderWidth: '0 1em 1.2em 1em',
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
    },
  },
}));
