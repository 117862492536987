import React, { useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import {
  Grow,
  MenuItem,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@mui/material';
import { RiArrowDownSLine } from 'react-icons/ri';
import { AiOutlineUser, AiOutlineLogin } from 'react-icons/ai';
import { FiPower } from 'react-icons/fi';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';

import { drawerWidth } from 'components/Sidebar';
import { StyledPopper } from 'components/CusMuiComp/CusPopper';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { useAuthContext } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/route-util';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const menuRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const { authState, setAuthState } = useAuthContext();

  //useState
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const onLoginClick = () => {
    const token = authState.token;
    window.open(
      `${process.env.REACT_APP_JOB_SEEKER_URL}/login/job-seeker?adminToken=${token}`
    );
  };


  //Handle
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <ConfirmDialog
        {...{
          open: confirmLogout,
          title: 'Logging Out',
          message: 'Are you sure?',
          onCancel: () => setConfirmLogout(false),
          onConfirm: () => setAuthState({ authed: false }),
        }}
      />
      <AppBar
        position='fixed'
        color='default'
        open={open}
        elevation={0}
        sx={{ borderBottom: (theme) => `2px solid ${theme.palette.divider}` }}
      >
        <Toolbar>
          <Avatar
            src={`${process.env.REACT_APP_BASE_PATH}/assets/images/logo2.png`}
            alt='Logo Workinga'
            variant='square'
            sx={{ width: 'auto', display: open ? 'none' : 'flex', mr: 2 }}
          />
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => {
              setOpen((e) => !e);
            }}
          >
            {!open ? (
              <AiOutlineMenuFold color={theme.palette.text.primary} />
            ) : (
              <AiOutlineMenuUnfold color={theme.palette.text.primary} />
            )}
          </IconButton>

          <Box flexGrow={1} />
          <Button
            startIcon={<AiOutlineLogin />}
            onClick={onLoginClick}
            variant='outlined'
          >
            Login as job seeker
          </Button>
          <Button
            ref={menuRef}
            startIcon={<Avatar />}
            endIcon={
              <RiArrowDownSLine size='18' color={theme.palette.text.primary} />
            }
            onClick={handleOpenMenu}
          >
            <Typography variant='body1' color={theme.palette.text.primary}>
              {authState.adminProfile?.name}
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <StyledPopper
        open={openMenu}
        anchorEl={menuRef.current}
        placement='bottom-end'
        transition
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
          },
        ]}
        sx={{ minWidth: 180 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: '100% 0 0' }}
            timeout={250}
          >
            <Paper
              elevation={4}
              sx={{ backgroundColor: 'common.white', borderRadius: 2 }}
            >
              <Box
                className='arrow'
                component='span'
                sx={{
                  position: 'absolute',
                  fontSize: 7,
                  width: '3em',
                  height: '3em',
                  '&::before': {
                    content: '""',
                    margin: 'auto',
                    display: 'block',
                    width: 0,
                    height: 0,
                    borderStyle: 'solid',
                  },
                }}
              />
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList>
                  <MenuItem
                    component='a'
                    onClick={() => {
                      navigate(ROUTE_PATH.user.profile);
                      handleCloseMenu();
                    }}
                    sx={{ py: 1.5 }}
                  >
                    <AiOutlineUser size={20} />
                    <Typography
                      sx={{
                        ml: 2,
                        fontWeight: (theme) =>
                          theme.typography.fontWeightMedium,
                      }}
                    >
                      Profile
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={() => setConfirmLogout(true)}
                    sx={{
                      py: 1.5,
                      color: 'error.dark',
                      borderTop: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <FiPower size={20} />
                    <Typography
                      sx={{
                        ml: 2,
                        fontWeight: (theme) =>
                          theme.typography.fontWeightMedium,
                      }}
                    >
                      Logout
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
}
