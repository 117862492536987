import { Button, Stack, Typography } from '@mui/material';
import { AiOutlineHome } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { NoPermission, userType } from 'utils/data-util';
import { ROUTE_PATH } from 'utils/route-util';
import { ReactComponent as Background } from 'permission.svg';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export default function Permission({
  slug,
  children,
}: {
  slug: string;
  children: React.ReactNode;
}) {
  //navigate
  const navigate = useNavigate();

  //get userType
  const { authState } = useContext(AuthContext);
  const type = Number(
    userType.find((user) => user.type === authState.adminProfile?.type)?.type
  );

  //Check Permission
  const notPermission = NoPermission[type].not_permission;
  const checkPermission = notPermission.includes(slug);

  return (
    <>
      {!checkPermission ? (
        children
      ) : (
        <Stack
          sx={{
            height: 'calc(100vh - 64px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
          }}
        >
          <Background />
          <Typography variant='h6'>
            Sorry, you are not authorized to access this page.
          </Typography>
          <Button
            variant='outlined'
            onClick={() => navigate(ROUTE_PATH.dashboard)}
            startIcon={<AiOutlineHome />}
          >
            Back Home
          </Button>
        </Stack>
      )}
    </>
  );
}
