import React, { useContext, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, Avatar, Collapse } from '@mui/material';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

import navigations from 'utils/navigation-util';
import useRouter from 'hook/useRouter';
import theme from 'themes';
import { userType } from 'utils/data-util';
import { NoPermission } from 'utils/data-util';
import { AuthContext } from 'contexts/AuthContext';

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: '0px 25px 30px #0000001A',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  boxShadow: '0px 25px 30px #0000001A',
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar({ open }: { open: boolean }) {
  //Variable
  const { location, navigate } = useRouter();
  //useState
  const [openCollapse, setOpenCollapse] = useState<{ [key: string]: boolean }>(
    navigations
      .filter((n) => n.subMenu.length > 0)
      .reduce((acc, n) => (acc = { ...acc, [n.menuTile]: false }), {})
  );

  // Handle
  const handleChangePage = ({
    menuName,
    link,
  }: {
    menuName: string;
    link?: string;
  }) => {
    if (!link) {
      // console.log('s', openCollapse);

      setOpenCollapse((pre) => ({
        ...pre,
        [menuName]: !openCollapse[menuName],
      }));
    } else {
      navigate(`${link}`);
    }
  };

  const { authState } = useContext(AuthContext);
  const type = Number(
    userType.find((user) => user.type === authState.adminProfile?.type)?.type
  );

  const notPermission = NoPermission[type].not_permission;

  return (
    <Drawer
      variant='permanent'
      open={open}
      PaperProps={{
        className: 'hide-scrollbar',
      }}
    >
      <DrawerHeader>
        <Avatar
          variant='square'
          src={`${process.env.REACT_APP_BASE_PATH}/assets/images/logo.png`}
          alt='Logo Workinga'
          sx={{ width: '100%' }}
        />
      </DrawerHeader>
      <Divider />
      <List>
        {navigations.map((menu, index) => {
          const menuTitle = menu.menuTile.toUpperCase().replace(' ', '_');

          return (
            <React.Fragment key={index}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  disabled={notPermission.includes(menuTitle)}
                  onClick={() => {
                    handleChangePage({
                      menuName: menu.menuTile,
                      link: menu.menuLinks,
                    });
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    bgcolor: location.pathname.includes(`${menu.menuLinks}`)
                      ? (theme) => alpha(theme.palette.primary.main, 0.1)
                      : (theme) => theme.palette.common.white,
                    borderRight: location.pathname.includes(`${menu.menuLinks}`)
                      ? `2px solid ${theme.palette.primary.main}`
                      : 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                      color: location.pathname.includes(`${menu.menuLinks}`)
                        ? (theme) => theme.palette.primary.main
                        : (theme) => theme.palette.secondary.main,
                    }}
                  >
                    {menu.menuStartIcon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      color: location.pathname.includes(`${menu.menuLinks}`)
                        ? (theme) => theme.palette.primary.main
                        : (theme) => theme.palette.text.primary,
                    }}
                    primary={menu.menuTile}
                  />
                  {menu.subMenu.length > 0 &&
                    (openCollapse[menu.menuTile] ? (
                      <ArrowUp2 size='16' variant='Outline' />
                    ) : (
                      <ArrowDown2 size='16' variant='Outline' />
                    ))}
                </ListItemButton>
              </ListItem>
              {menu.subMenu.length > 0 && (
                <Collapse
                  in={openCollapse[menu.menuTile]}
                  unmountOnExit
                  timeout={300}
                >
                  <List disablePadding>
                    {menu.subMenu.map((subMenu, index) => {
                      const subTile = String(
                        subMenu.subTile?.toUpperCase().replace(' ', '_')
                      );
                      return (
                        <ListItemButton
                          disabled={notPermission.includes(subTile)}
                          key={index}
                          onClick={() => {
                            navigate(`${subMenu.subLinks}`);
                          }}
                          sx={{
                            pl: 7,
                            bgcolor: location.pathname.includes(
                              `${subMenu.subLinks}`
                            )
                              ? (theme) =>
                                  alpha(theme.palette.primary.main, 0.1)
                              : (theme) => theme.palette.common.white,

                            borderRight: location.pathname.includes(
                              `${subMenu.subLinks}`
                            )
                              ? `2px solid ${theme.palette.primary.main}`
                              : 0,
                          }}
                        >
                          <ListItemText
                            primary={subMenu.subTile}
                            sx={{
                              color: location.pathname.includes(
                                `${subMenu.subLinks}`
                              )
                                ? (theme) => theme.palette.primary.main
                                : (theme) => theme.palette.secondary.main,
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
}
