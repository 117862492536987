import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { useState } from 'react';
import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';

export default function Layout() {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Navbar {...{ open, setOpen }} />
        <Sidebar {...{ open, setOpen }} />
        <Box
          component='main'
          id='main'
          flexGrow={1}
          sx={{ overflow: 'hidden' }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
