import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

import theme from 'themes';
import AllRoutes from 'routes';
import { AuthWrapper } from 'contexts/AuthContext';

function App() {
  return (
    <Router basename={process.env.REACT_APP_BASE_PATH}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthWrapper>
          <AllRoutes />
        </AuthWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default App;
