export enum JobStatus {
  UNDEFINED = 0,
  UNDER_REVIEW = 1,
  PUBLISHED = 2,
  CLOSED = 3,
}

export enum InterviewStatus {
  Pending = 1,
  Completed = 2,
  Closed = 3,
  Cancelled = 4,
  Accepted = 5,
  Rejected = 6,
  Started = 7,
}

export enum InterviewMethod {
  OfflineInterview = 1,
  OnlineInterview = 2,
}

export enum InterviewType {
  Appointment = 1,
  OnlineInterview = 2,
  Request = 3,
  FreelanceJobInterview = 4,
}

export enum NewOfferLetterStatus {
  Pending = 1,
  Accept = 2,
  Reject = 3,
}

export enum ApplyJobSuccessStatus {
  Passed = 1,
  In_Employment = 2,
  Terminated = 3,
  Resign = 4,
}

export enum GenderType {
  Male = 1,
  Female = 2,
  Both = 3,
}

export enum JobSort {
  CreatedDate = 'createdAt',
  UpdatedDate = 'updatedAt',
}

export enum Order {
  ASC = 1,
  DESC = 2,
}

export enum PublicHoliday {
  'Follow by Labor Law' = 1,
  'Only for big holiday' = 2,
}

export enum Days {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum EmpType {
  'false' = 0,
  'true' = 1,
}

export enum PackageStatus {
  UNDEFINED = 0,
  PENDING = 1,
  CONFIRMED = 2,
  CLOSED = 3,
}

export enum PackageType {
  free = 1,
  basic = 4,
  signature = 3,
  signaturePlus = 2,
}

export enum ApplyJobStatus {
  UNDEFINED = 0,
  PENDING = 1,
  FAILED = 2,
  FAILED_SHORTLISTED = 3,
  INTRODUCED = 4,
  PASSED = 5,
  IN_REVIEW = 6,
  INTERVIEW = 7,
  FINAL_INTERVIEW = 8,
  SHORTLISTED = 9,
  REJECTED = 10,
  CANNOT_REACH = 11,
  TERMINATED = -1,
}

export enum JobLevel {
  UNDEFINED = 0,
  NON_PRIOR_EXPERIENCE = 1,
  JUNIOR = 2,
  SUPERVISOR_AND_MANAGERIAL = 3,
  TOP_EXECUTIVE_MANAGEMENT = 4,
}

export enum BlogPostStatus {
  UNDEFINED = 0,
  DRAFT = 1,
  PUBLISH = 2,
}

export enum VideotStatus {
  UNDEFINED = 0,
  DRAFT = 1,
  PUBLISH = 2,
}

export enum MaritalStatus {
  SINGLE = 1,
  MARRIED = 2,
  DIVORCED = 3,
}
